import { POST_PAYMENT_TYPE_INSURANCE_ID } from "../../../../utilities/staticConfigs";

export const initialState = {
    postPaymentDetails: {
        insurancePostPayment: false,
        postPaymentType: POST_PAYMENT_TYPE_INSURANCE_ID,
        paymentPK: "",
        patientPaymentPost:false,
        patientPaymentPatientPK:"",
        patientPaymentPostType:false,
    },
    insurancePaymentData:{

    },

    patientPaymentData:{

    },
    patientAppliedProcedures:[],
    patientPostDetails:{
        postManually:false,
        autoPost:false,
        showPreview:false,
        showContinuePosting:false,
        showAppliedPayments:false,
        radioValue:"",
        postType:"",
        patientName:"",
        patientId:"",
        paymentBalnceAmount:'',
        PrintReceiptonsaving:false,
        claimPK:"",
        unAppliedAmount:0,
        sumApplied:0,
        alreadyApplied:0,
        sumAdjustments:0
    },
    patientPaymentVariables:{
        paymentMode:"",
        paymentType:"",
        paymentSubType:"",
        paymentAmount:"",
        paymentReference:"",
        payment_date:"",
        selectedPaymentPlan:"",
        creditCard:"",
        paymentNote:"",
        PrintReceiptonsaving:false,
        includeFamilyMemberBalances:"",
    },
    claimSearchVariables:{
        claim_id: "",
        patient_id: "",
        claim_status: "",
        responsibility_type: "",
        claim_type: "",
        claim_date_of_service_from: "",
        claim_date_of_service_to: "",
        claim_date_type: 1,
        claim_submitted_date_from: "",
        claim_submitted_date_to: "",
        claim_submitted_date_type: 1,
        reversal_payent: false,
    },
    editPaymentVariables:{
        payer: "",
        payment_date: "",
        paymentDate: "",
        reference_check_number: "",
        amount: "",
        payment_mode: "",
        card_type: null,
        level_adjustments: "",
        adjustment_type: [],
        note: "",
        custom_payment_id: "",
    },
    insurancePaymentVariables: {
        payer: "",
        paymentDate: "",
        payment_date: "",
        reference_check_number: "",
        amount: "",
        payment_mode: "",
        card_type: null,
        level_adjustments: 0,
        adjustment_type: [],
        note: "",
        creditCard:""
    },
    claimList:{},
    patientClaims:{},
    procedureData:{},
    procedureError:{},
    oldResponsibility:{},
    responsibility_type:{},
    patientSelectedData: [],
    patientAdvanceSearchData: [],
    selectedPatient: "",
    form_error:{
    patientId: "",
    paymentDate: "",
    paymentReference: "",
    paymentAmount: "",
    paymentType: "",
    paymentSubType:"",
    paymentPlanList:[],
    paymentPlan_status:true,
    paymentMode: "",
    selectedPaymentPlan:"",
    creditCard:"",
    paymentNote:"",
    PrintReceiptonsaving:false,
    },
    autoPost:false,
    selectedPayer: [],
    selectedClaimVals:[],
    patientDropdown:[],
    selectClaim:"",
    selectCharge: "charges",
    prTotal:"",
    payersList: [],
    adjustmentTypes:[],
    adjustment_1_codes_list:[],
    responsibilityData:[],
    adjustment_2_codes_list:[],
    payerListLoading: false,
    paymentPostLoading:false,
    getPaymentDetailsLoading:false,
    searchClaimLoading:false,
    savedProcedureLoading:false,
    notifyMessage: "",
    showNotification: false,
    statusTag: "",
    showAlertMessage:false,
    alertMessage:"",
    header:"",
    searchText:"",
    searchOption:"",
    showPostAsCreditModal:false,
    showEditPaymentModal:false,
    searchModalOpen:false,
    isEditButtondisable:false,
    openClaimWindow:false,
    claimIdOpened:"",
    masterClaims:[],
    claimStatusList:[],
    claimSubStatusList:[],
    procedureValueChanged:false,
    validationErrorModal:false,
    saveProcedureLoading:false,
    validationError:[],
    patientClaimsData:[],
    dataLoading:false,
    error:{},
    saveTriggered:false,
    searchQuery:"",
    redirectToReceipt:false,
    clickedFromTable:false,
}