import { createSlice, current } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { checkForAlert, CheckForPaymentExists, getAdjustmentCodeList, GetAdjustmentTypes, getClaimStatusAndSubStatus, getpatientClaimList, getPatientPaymentClaimDetails, getPatientPaymentData, GetPatientPaymentPlans, getPaymentDetails, getResponibilityTypePatient, handleProcedureSubmit, handleRadioButtonChange, ListPatientAppliedClaims, listPatientClaims, listPatientDropdownData, listProcedureClaims, onSavePatientPayment, onSavePatientPaymentProcedure, onSavePayment, postPaymentManually, savedProcedureData, searchClaimIDS, searchPayerList } from "./asyncThunkAPI";
import { format } from "date-fns";
import i18n from "../../../../utilities/i18n";
import { checkAdjustmentCode, checkAdjustmentCodeDenialId, checkForDataChanges, checkForErrors, createJsonData, createJsonDataPatientPayment, createPatientProcedureData, createProcedureData, createReverseData, generateTableData, generateTablePatientData, getSumAppliedUnApplied, updateAppliedAndUnappliedAmount } from "./paymentDataProcessor";
import { filterDateValues } from "../../../../utilities/dateFilterCalculation";
import { setStorage } from "../../../../utilities/browserStorage";
import { POST_PAYMENT_TYPE_INSURANCE_ID, POST_PAYMENT_TYPE_PATIENT_ID } from "../../../../utilities/staticConfigs";
const paymentSlice = createSlice({
    name: 'paymentSlice',
    initialState,
    reducers: {
        /**
         * store payment details when table payment id click
         * @param {*} state 
         * @param {*} actions 
         */
        storePaymentDetailsTableClick: (state, actions) => {
            state.postPaymentDetails.insurancePostPayment = false;
            state.postPaymentDetails.postPaymentType = actions.payload.postPaymentType;
            state.postPaymentDetails.paymentPK = actions.payload.paymentPK;
            state.clickedFromTable = true;
            if (actions.payload.patientPaymentPost == 1) {
                state.postPaymentDetails.patientPaymentPost = actions.payload.patientPaymentPost;
                state.postPaymentDetails.patientPaymentPatientPK = actions.payload.patientPaymentPatientPK;
                state.postPaymentDetails.patientPaymentPostType = actions.payload.patientPaymentPostType;
            }
        },
        /**
        * function used to store payment types @insurance and @payment
        * @param {*} state 
        * @param {*} actions 
        */
        setPostPaymentType: (state, actions) => {
            state.postPaymentDetails.postPaymentType = actions.payload;
            if(actions.payload===1) {
                state.paymentPlan_status=true;
            }else{
                state.paymentPlan_status=false;  
            }
        },

        /**
         * function to update selected payer details
         * @param {*} state 
         * @param {*} actions 
         */
        updatePayerDetails: (state, actions) => {
            let label = ""
            let selected = [];
            if (Array.isArray(actions.payload.e) && actions.payload.e.length > 0) {
                label = actions.payload.e[0].id;
                selected = actions.payload.e
            }
            if (actions.payload.action == "post") {
                state.insurancePaymentVariables.payer = label;
            } else {
                state.editPaymentVariables.payer = label;
            }
            state.selectedPayer = selected;
        },
        /**
         * insurance date value change
         * @param {*} state 
         * @param {*} actions 
         */
        updateDate: (state, actions) => {
            let { value, name, key } = actions.payload
            if (key !== "patientPaymentVariables") {
                state[key][name] = value
            }
            if (
                value &&
                new Date(value).getTime() > 0
            ) {

                state[key].payment_date = format(
                    value,
                    "yyyy-MM-dd"
                )
            }else{
                state[key].payment_date="" 
            }
        },

        /**
         * insurance other field value changes
         * @param {*} state 
         * @param {*} actions 
         */
        updateValueChange: (state, actions) => {
            let { name, value, key } = actions.payload;
            if (name === "selectCharge") {
                state.selectCharge = value;
            } else if (name === "amount" || name === "level_adjustments") {
                let newValue = value.trim().replace(/[^0-9.]/g, '');
                newValue = newValue.replace(/^(\d*\.\d{0,2})\d*$/, '$1');
                state[key][name] = newValue;
            } else {
                if (name === "showPreview" && key === "patientPaymentVariables") {
                    let valueSet = {
                        ...state.patientPostDetails,
                        [name]: value
                    }
                    state.patientPostDetails = valueSet;
                } else if (name === "paymentAmount" && key === "patientPaymentVariables") {
                    let newValue = value.trim().replace(/[^0-9.]/g, "");
                    newValue = newValue.replace(/^(\d*\.\d{0,2})\d*$/, '$1');
                    state[key][name] = newValue;
                } else {
                    state[key][name] = value;
                }
            }
        },

        setPatientPostDetails: (state, actions) => {
            let { value, name, key } = actions.payload;
            let valueSet = {
                ...state.patientPostDetails,
                [name]: value,
            }

            if (key === "credit") {
                valueSet = {
                    ...valueSet,
                    postManually: false,
                    autoPost: false,
                }
            }
            state.patientPostDetails = valueSet;
        },


        setFormError: (state, actions) => {
            let formError = {
                ...state.form_error,
                [actions.payload.name]: actions.payload.value
            }
            state.form_error = formError;
            state.patientPostDetails.postManually = false;
            state.patientPostDetails.autoPost = false;
        },
        /**
         * create new notification
         * @param {*} state 
         * @param {*} actions 
         */
        showNotification: (state, actions) => {
            state.notifyMessage = actions.payload.message;
            state.showNotification = true;
            state.statusTag = actions.payload.tag;
        },

        /**
         * function to reset notification details
         * @param {*} state 
         */
        resetNotifyWindow: (state) => {
            state.notifyMessage = "";
            state.showNotification = false;
            state.statusTag = "";
            state.saveTriggered = false;
        },
        /**
         * show alert message 
         * @param {} state 
         * @param {*} actions 
         */
        showAlertMessage: (state, actions) => {
            state.alertMessage = actions.payload;
            state.showAlertMessage = true;
        },
        /**
         * reset alert message
         * @param {*} state 
         * @param {*} actions 
         */
        resetAlertMessage: (state) => {
            state.alertMessage = "";
            state.showAlertMessage = false;
        },


        showLoadingBar: (state, action) => {
            state.paymentPostLoading = action.payload;
        },

        /**
         * show model windows
         * @param {*} state 
         * @param {*} action 
         */
        showModalWindow: (state, action) => {
            state[action.payload.key] = action.payload.open;
            state.header = action.payload.header;
        },

        /**
         * hide model windows
         * @param {*} state 
         * @param {*} action 
         */
        hideModalWindow: (state, action) => {
            state[action.payload.key] = action.payload.open;
            state.header = ""
            state.validationError = [];
            state.searchOption = "",
            state.searchText = "";
        },
        /**
         * remove details on reset payment button click
         * @param {*} state 
         */
        resetPaymentDetails: (state) => {
            state.selectCharge = "";
            state.insurancePaymentVariables = initialState.insurancePaymentVariables;
            state.postPaymentDetaills = initialState.postPaymentDetails;
            state.selectedPayer=initialState.selectedPayer
        },

        //reset claim search variables
        resetSearchVariable: (state) => {
            state.claimSearchVariables = initialState.claimSearchVariables;
        },

        removeAdjustmentCodeList: (state, actions) => {
            state[actions.payload] = [];
        },

        /**
         * to update claim search variables
         * @param {*} state 
         * @param {*} actions 
         */
        updateClaimSearchVariables: (state, actions) => {
            state.claimSearchVariables.claim_id = actions.payload
        },

        /**
         * to update procdure level field values
         */
        procedureValueChange: (state, actions) => {
            let { id, value, field } = actions.payload;
            if(field==="allowed_amount" || field==="paid_amount"){
                value=String(value.trim())
                .replace(/[^0-9.]/g, "") // Remove all non-numeric and non-period characters
                .replace(/(\..*?)\./g, "$1") // Remove all extra decimal points
                .replace(/(\.\d{2})\d*/g, "$1") // Allow only two digits after the first decimal point
                .substring(0, 9); // Limit the length to 9 characters
            }

            state.procedureData[id] = {
                ...state.procedureData[id],
                [field]: value

            };
            if(field == "responsibility_type"){
                state.procedureData[id] = {
                    ...state.procedureData[id],
                    "claim_status_id": "",
                    "claim_sub_status_id":""
                };
            }
        },

        /**
         * reduc function to add adjustment code 1
         * @param {*} state 
         * @param {*} actions 
         * @returns 
         */
        addAdjustmentCode1: (state, actions) => {
            let { e, id } = actions.payload;
            let procedureCodes = [...state.procedureData[id]["adjustment_code1_data"], ...state.procedureData[id]["adjustment_code2_data"]];
            if (procedureCodes.some((item) => item.id === e[0].id)) {
                state.showNotification = true;
                state.notifyMessage = "Duplicate entry.";
                state.statusTag = "error";
                return
            } if (
                (e[0].label === 'PR-1' || e[0].label === 'PR-2' || e[0].label === 'PR-3')
            ) {
                state.showNotification = true;
                state.notifyMessage = "PR-1 PR-2 & PR-3 Codes should be added to Adjustment 2 Code set.";
                state.statusTag = "error";
                return
            }

            state.procedureData[id]["adjustment_code1_data"] = [...state.procedureData[id]["adjustment_code1_data"], { ...e[0], amount: 0 }]
        },

        /**
        * reduc function to add adjustment code 1
        * @param {*} state 
        * @param {*} actions 
        * @returns 
        */
        addAdjustmentCode2: (state, actions) => {
            let { e, id } = actions.payload;
            let procedureCodes = [...state.procedureData[id]["adjustment_code1_data"], ...state.procedureData[id]["adjustment_code2_data"]];
            if (procedureCodes.some((item) => item.id === e[0].id)) {
                state.showNotification = true;
                state.notifyMessage = "Duplicate entry.";
                state.statusTag = "error";
                return  
            } if (
                (e[0].label === 'CO-253')
            ) {
                state.showNotification = true;
                state.notifyMessage = "CO-253 should be added to Adjustment 1 Code set.";
                state.statusTag = "error";
                return
            }

            state.procedureData[id]["adjustment_code2_data"] = [...state.procedureData[id]["adjustment_code2_data"], { ...e[0], amount: 0 }]
        },

        /**
         * redux function to remove adjustment codes
         * @param {*} state 
         * @param {*} actions 
         */
        removeAdjustmentCode: (state, actions) => {
            let { item, index, id, field } = actions.payload;
            const newEraAdjustments1 = [...state.procedureData[id][field]];
            newEraAdjustments1.splice(index, 1);
            state.procedureData[id][field] = newEraAdjustments1;
            if (
                (item.label === 'PR-1' || item.label === 'PR-2' || item.label === 'PR-3')
            ) {
                switch (item.label) {
                    case 'PR-1':
                        state.procedureData[id]["deductibles"] = '';
                        break;
                    case 'PR-2':
                        state.procedureData[id]["co_insurance"] = '';
                        break;
                    case 'PR-3':
                        state.procedureData[id]["copay"] = '';
                        break;
                    default:
                        break;
                }
            } else {
                state.procedureData[id]["claim_status_id"] = "";
                state.procedureData[id]["claim_sub_status_id"] = "";
            }
        },

        /**
         * to add adjustment amount
         * @param {*} state 
         * @param {*} actions 
         */
        addAdjustmentAmount: (state, actions) => {
            let { value, adjustment, id, field } = actions.payload;
            let { procedureData } = current(state);

            let procedureRowToEdit = { ...procedureData[id] };

            let fieldArray = [...procedureRowToEdit[field]];

            let procedureIndex = fieldArray.findIndex((item) => item.id === adjustment.id);

            if (procedureIndex !== -1) {
                fieldArray[procedureIndex] = {
                    ...fieldArray[procedureIndex],
                    amount: value,
                };
            }

            procedureRowToEdit[field] = fieldArray;

            if (
                adjustment.label === 'PR-1' || adjustment.label === 'PR-2' || adjustment.label === 'PR-3'
            ) {
                switch (adjustment.label) {
                    case 'PR-1':
                        procedureRowToEdit["deductibles"] = value;
                        break;
                    case 'PR-2':
                        procedureRowToEdit["co_insurance"] = value;
                        break;
                    case 'PR-3':
                        procedureRowToEdit["copay"] = value;
                        break;
                    default:
                        break;
                }
            }
            state.procedureData[id] = procedureRowToEdit;
        },

        /**
         * adding remark codes to list
         * @param {*} state 
         * @param {*} actions 
         */
        onAddRemarkCodes: (state, actions) => {
            let { value, id } = actions.payload;
            if (value.length > 0) {
                let arrayId = [];
                let arrayCodes = [];
                value.map((item) => {
                    arrayId.push(item.id);
                    arrayCodes.push(item);
                });
                if (value) {
                    state.procedureData[id]['remark_code'] = arrayId;
                } else {
                    state.procedureData[id]['remark_code'] = [];
                }
                state.procedureData[id]['selectedRemarkCodes'] = arrayCodes;
            }
            else {
                state.procedureData[id]['remark_code'] = [];
                state.procedureData[id]['selectedRemarkCodes'] = [];
            }
        },

        /**
         * procedure modal open
         * @param {*} state 
         * @param {*} actions 
         */
        openClaimModalWindow: (state, actions) => {
            state.openClaimWindow = true;
            let { jsonObject, oldResponsibilityObject, responsibility_type } = createProcedureData(actions.payload);
            state.procedureData = jsonObject;
            state.oldResponsibility = oldResponsibilityObject;
            state.claimIdOpened = actions.payload.custom_claim_id;
            state.responsibility_type = responsibility_type;
        },

        openPatientModalWindow: (state, actions) => {
            state.openClaimWindow = true;
            let jsonObject = createPatientProcedureData(actions.payload);
            state.procedureData = jsonObject;
            state.claimIdOpened = actions.payload.custom_claim_id;
        },

        //search claim date

        onHandleSearchClaimDateChange: (state, actions) => {
            let data = {
                ...state.claimSearchVariables,
                [actions.payload.key]: actions.payload.value
            }
            state.claimSearchVariables = data;
        },

        //search value changes 
        onHandleValueChange: (state, actions) => {
            if (actions.payload.name === "selectCharge") {
                state.selectCharge = actions.payload.value
            }
            if (actions.payload.name == "searchText") {
                state.searchText = actions.payload.value;
            }
            if (actions.payload.name == "searchOption") {
                state.searchOption = actions.payload.value;
            }
        },

        //handle advance search field values
        onHandleAdvanceSearchFields: (state, actions) => {
            const { name, value } = actions.payload;

            if (name === "claim_status") {
                state.claimSearchVariables.claim_status = value;
            }
            if (name === "responsibility_type") {
                state.claimSearchVariables.responsibility_type = value;
            }
            if (name === "claim_type") {
                state.claimSearchVariables.claim_type = value;
            }
            if (name === "claim_date_type") {
                let dateRange = filterDateValues(value);
                state.claimSearchVariables.claim_date_type = value;
                state.claimSearchVariables.claim_date_of_service_from = dateRange.fromDate
                state.claimSearchVariables.claim_date_of_service_to = dateRange.toDate
            }
            if (name === "claim_submitted_date_type") {
                let dateRange = filterDateValues(value);
                state.claimSearchVariables.claim_submitted_date_type = value;
                state.claimSearchVariables.claim_submitted_date_from = dateRange.fromDate
                state.claimSearchVariables.claim_submitted_date_to = dateRange.toDate
            }
        },

        /**
         * procedure modal close
         * @param {*} state 
         */
        handleProcedureEditWindowClose: (state) => {
            state.openClaimWindow = false;
            state.procedureData = {};
            state.procedureError = initialState.procedureError;
            state.oldResponsibility = initialState.oldResponsibility;
            state.responsibility_type = initialState.responsibility_type;
            state.claimIdOpened = "";
        },




        /**
         * Text field bluring time checkings happen here
         * @param {*} state 
         * @param {*} actions 
         */
        onFieldBlurApply: (state, actions) => {
            let { procedureData, oldResponsibility, procedureError, responsibility_type,claimStatusList } = current(state);
            let { responsibilityID, closing_balance } = checkForDataChanges(procedureData[actions.payload.id],
                actions.payload.field, oldResponsibility[actions.payload.index], responsibility_type[actions.payload.index]);
            let errors = checkForErrors(procedureData[actions.payload.id], procedureError[actions.payload.id]);
            state.procedureError[actions.payload.id] = errors;
            
            if (responsibilityID !== state.procedureData[actions.payload.id].responsibility_type) {
                state.procedureData[actions.payload.id] = {
                    ...state.procedureData[actions.payload.id],
                    responsibility_type: responsibilityID,
                    closing_balance: closing_balance,
                    claim_status_id: "",
                    claim_sub_status_id: ""
                }
            } else {
                state.procedureData[actions.payload.id] = {
                    ...state.procedureData[actions.payload.id],
                    responsibility_type: responsibilityID,
                    closing_balance: closing_balance,
                }
            }

            let primaryClaimStatus = claimStatusList.find(item => item.name.toLowerCase() === "Claim Denied at Primary".toLowerCase() && item.priorities.includes(1)).id 
            let secondaryClaimStatus = claimStatusList.find(item => item.name.toLowerCase() === "Claim Denied at Secondary".toLowerCase() && item.priorities.includes(2)).id 
            let TertiaryClaimStatus = claimStatusList.find(item => item.name.toLowerCase() === "Claim Denied at Tertiary".toLowerCase() && item.priorities.includes(3)).id

            let codeStatus = checkAdjustmentCode(procedureData[actions.payload.id].adjustment_code2_data);
            let claimSubStatusId = checkAdjustmentCodeDenialId(procedureData[actions.payload.id].adjustment_code2_data)

            if (codeStatus == true) {
                if (primaryClaimStatus && responsibilityID == 1) {
                    state.procedureData[actions.payload.id] = {
                        ...state.procedureData[actions.payload.id],
                        claim_status_id: primaryClaimStatus,
                        claim_sub_status_id: claimSubStatusId
                    }
                }
                if (secondaryClaimStatus && responsibilityID == 2) {
                    state.procedureData[actions.payload.id] = {
                        ...state.procedureData[actions.payload.id],
                        claim_status_id: secondaryClaimStatus,
                        claim_sub_status_id: claimSubStatusId
                    }
                }
                if (TertiaryClaimStatus && responsibilityID == 3) {
                    state.procedureData[actions.payload.id] = {
                        ...state.procedureData[actions.payload.id],
                        claim_status_id: TertiaryClaimStatus,
                        claim_sub_status_id: claimSubStatusId
                    }
                }
                if (primaryClaimStatus && responsibilityID == 4) {
                    state.procedureData[actions.payload.id] = {
                        ...state.procedureData[actions.payload.id],
                        claim_status_id: primaryClaimStatus,
                        claim_sub_status_id: claimSubStatusId
                    }
                }
                
            }
        },

        /**
         * 
         * @param {*} state 
         */
        removeAllPaymentDetails: (state, action) => {
            state.claimList = {};
            state.procedureData = {};
            let data = {
                insurancePostPayment: false,
                postPaymentType: action.payload === "patient" ? POST_PAYMENT_TYPE_PATIENT_ID : POST_PAYMENT_TYPE_INSURANCE_ID,
                paymentPK: "",
                patientPaymentPost: false,
                patientPaymentPatientPK: "",
                patientPaymentPostType: false,
            }
            state.postPaymentDetails = data;
            state.insurancePaymentData = initialState.insurancePaymentData;
            state.claimSearchVariables = initialState.claimSearchVariables;
            state.patientPostDetails = initialState.patientPostDetails;
            state.patientPaymentVariables = initialState.patientPaymentVariables;
            state.patientAppliedProcedures = initialState.patientAppliedProcedures;
            state.patientPaymentData = initialState.patientPaymentData;
            state.patientClaims = [];
            state.tableRow = [];
            state.procedureError = initialState.procedureError;
            state.editPaymentVariables = initialState.editPaymentVariables;
            state.insurancePaymentVariables = initialState.insurancePaymentVariables;
            state.selectedPayer = [];
            state.selectedClaimVals = [];
            state.selectCharge = initialState.selectCharge;
            state.prTotal = "";
            state.error = {};
            state.clickedFromTable = false;
            state.paymentPlan_status=true;
            state.patientSelectedData= [];
            state.patientAdvanceSearchData=[];
            state.selectedPatient= "";
        },
        /**show validation erros */
        validationErrorShowing: (state, actions) => {
            state.validationError = actions.payload.errors;
            state.validationErrorModal = actions.payload.open;
        },


        handleUpdateTableData: (state) => {
            let { procedureData, claimIdOpened } = current(state);
            const { totalAdjustment1Edited, totalAdjustment2Edited, totalAllowedAmountEdited, totalBalanceEdited, totalChargeEdited,
                totalClosingBalanceEdited, totalPaidAmountEdited, totalReceiptEdited, procedures
            } = generateTableData(procedureData);
            state.claimList[claimIdOpened] = {
                ...state.claimList[claimIdOpened],
                totalAdjustment1Edited: totalAdjustment1Edited,
                totalAdjustment2Edited: totalAdjustment2Edited,
                totalAllowedAmountEdited: totalAllowedAmountEdited,
                totalBalanceEdited: totalBalanceEdited,
                totalChargeEdited: totalChargeEdited,
                totalClosingBalanceEdited: totalClosingBalanceEdited,
                totalReceiptEdited: totalReceiptEdited,
                totalPaidAmountEdited: totalPaidAmountEdited,
                procedures: procedures,
                edited: true
            }

            state.openClaimWindow = false;
            state.procedureData = {};
            state.procedureError = initialState.procedureError;
            state.oldResponsibility = initialState.oldResponsibility;
            state.responsibility_type = initialState.responsibility_type;
            state.claimIdOpened = "";

            const { appliedAmount, unAppliedAmount } = updateAppliedAndUnappliedAmount(state.claimList, state.insurancePaymentData);
            let data = {
                ...state.insurancePaymentData,
                unAppliedAmount: unAppliedAmount,
                appliedAmount: appliedAmount
            }

            state.insurancePaymentData = data;
        },

        //eslint-disable-next-line
        handlePatientTableDataUpdate: (state, actions) => {
            state.oepn
            let { procedureData, claimIdOpened } = current(state);
            const { totalChargeEdited, totalReceiptEdited, totalBalanceEdited, totalAdjustmentsEdited, totalApplyAmount, totalClosingBalanceEdited, procedures } = generateTablePatientData(procedureData);
            state.patientClaims[claimIdOpened] = {
                ...state.patientClaims[claimIdOpened],
                totalChargeEdited: totalChargeEdited,
                totalReceiptEdited: totalReceiptEdited,
                totalBalanceEdited: totalBalanceEdited,
                totalAdjustmentsEdited: totalAdjustmentsEdited,
                totalApplyAmount: totalApplyAmount,
                totalClosingBalanceEdited: totalClosingBalanceEdited,
                procedures: procedures,
                edited: true
            }
            state.patientPostDetails.sumApplied = totalApplyAmount;
            state.patientPostDetails.unAppliedAmount = parseFloat(state.patientPostDetails.paymentBalnceAmount) - totalApplyAmount;
            state.openClaimWindow = false;
            state.procedureData = {};
            state.claimIdOpened = "";
        },

        /**updateQuery */
        updateQueryDetails: (state, actions) => {
            state.searchQuery = actions.payload
        },

        //remove a added claim from the table
        removeClaimFromTable: (state, actions) => {
            delete state.claimList[actions.payload];
            const { appliedAmount, unAppliedAmount } = updateAppliedAndUnappliedAmount(state.claimList, state.insurancePaymentData);
            let data = {
                ...state.insurancePaymentData,
                unAppliedAmount: unAppliedAmount,
                appliedAmount: appliedAmount
            }

            state.insurancePaymentData = data;
        },

        //remove a procedure from the pop up
        removeProcedureFromTable: (state, actions) => {
            let { procedureData } = current(state);
            let id = procedureData[actions.payload].id;

            for (const key in procedureData) {

                if (id === procedureData[key].id && procedureData[key].entry_reversed === true) {

                    state.procedureData[key] = {
                        ...procedureData[key],
                        entry_reversed: false
                    };
                }
            }

            delete state.procedureData[actions.payload];
        },

        //reverse a payment 
        reversePaymentData: (state, actions) => {
            let { id, index } = actions.payload;
            let updatedData = createReverseData(state.procedureData[id], index, state.procedureData, id);
            state.procedureData = updatedData;
        },

        //edit payment detaails,
        onHandleEditPayment: (state, actions) => {
            let { value, name } = actions.payload;
            let data = {
                ...state.editPaymentVariables,
                [name]: value
            }

            if (name === 'payment_mode') {
                data = {
                    ...data,
                    ["reference_check_number"]: "",
                    ["amount"]: ""
                }
            }

            state.editPaymentVariables = data;
        },

        //on patient edit details date change done here
        onHandleEditPaymentDate: (state, actions) => {
            let { value, name } = actions.payload;
            let data = {
                ...state.editPaymentVariables,
                [name]: value
            }
            if (name == 'payment_mode') {
                data = {
                    ...data,
                    ["amount"]: "",
                    ["reference_check_number"]: "",
                }
            }

            state.editPaymentVariables = data;
        },

        //function ivoke when post payment type is changed
        onChangePostPaymentType: (state, actions) => {
            if (actions.payload === "manually") {
                if (!state.patientPostDetails.postManually) {
                    //CHECK ALL MANDATORY FIELDS ARE FILLED
                    let data = {
                        ...state.patientPostDetails,
                        postManually: !state.patientPostDetails.postManually,
                        autoPost: false
                    }
                    state.patientPostDetails = data;
                } else {
                    let data = {
                        ...state.patientPostDetails,
                        postManually: !state.patientPostDetails.postManually,
                        autoPost: false
                    }
                    state.patientPostDetails = data;
                }
            } else {
                if (!state.patientPostDetails.autoPost) {
                    //CHECK ALL MANDATORY FIELDS ARE FILLED
                    let data = {
                        ...state.patientPostDetails,
                        postManually: false,
                        autoPost: !state.patientPostDetails.autoPost
                    }
                    state.patientPostDetails = data;
                } else {
                    let data = {
                        ...state.patientPostDetails,
                        postManually: false,
                        autoPost: !state.patientPostDetails.autoPost
                    }
                    state.patientPostDetails = data;
                }
            }
        },

        //function ivoke when continue posting button is clicked 
        onContinuePosting: (state) => {
            state.selectCharge = "charges";
            state.patientPostDetails.showContinuePosting = true;
            state.patientPostDetails.showAppliedPayments = false;
            state.patientPostDetails.showPreview = true;
        },

        //function ivoke when patient payment procedure level changes
        onHandleApplyPaient: (state, actions) => {
            let responsibility_type = false
            let { value, id, field } = actions.payload;
            let data = {
                ...state.procedureData[id],
                [field]: value
            }

            let value1 = (parseFloat(data["apply_payment"] ? data["apply_payment"] : 0)) +
                parseFloat((data["apply_adjustments"] ? data["apply_adjustments"] : 0))
            let closing_balance = parseFloat(data["opening_balance"]) - value1;
            data = {
                ...data,
                ["closing_balance"]: closing_balance
            }

            if (field === "apply_payment" || field === "apply_adjustments") {
                if (closing_balance <= 0) {
                    responsibility_type = 5;
                }
                else {
                    responsibility_type = 4;
                }
                if (responsibility_type !== state.procedureData[id].responsibility_type) {
                    data = {
                        ...data,
                        ["responsibility_type"]: responsibility_type,
                        ["claim_status_id"]: null,
                        ["claim_sub_status_id"]: null
                    }
                } else {
                    data = {
                        ...data,
                        ["responsibility_type"]: responsibility_type,
                    }
                }
            }


            state.procedureData[id] = data
        },

        //function ivoke when claim id is selected in patient payment 
        onHandleClaimIDChange: (state, actions) => {
            if (actions.payload.length > 0) {
                state.patientPostDetails.claimPK = actions.payload[0].id;
                state.selectClaim = actions.payload[0].name;
            } else {
                state.patientPostDetails.claimPK = actions.payload[0].id;
                state.selectClaim = actions.payload[0].name;
            }
        },

        //function ivoke when patient selection in patient post payment window
        onPatientDropDownSelectAction:(state,actions)=>{
            let {patientSelected, patientAdvSearchData, selectPatient } = actions.payload;
            state.patientSelectedData = patientSelected;
            state.patientAdvanceSearchData = patientAdvSearchData;
            state.selectedPatient = selectPatient;
        },

        //function ivoke when patient procedure level details reset after changes done
        resetPatientProcedureDetails:(state,actions)=>{
            let { id } = actions.payload;
            let data = {
                ...state.procedureData[id],
                "closing_balance": "0.00",
                "apply_payment":"0.00",
                "apply_adjustments":"0.00"
            }
            state.procedureData[id] = data
        },

        //reset all state
        resetToInitialState:()=>initialState
    },
    extraReducers: (builder) => {
        builder.addCase(searchPayerList.pending, (state) => {
            state.payerListLoading = true;
        })
        builder.addCase(searchPayerList.rejected, (state) => {
            state.payerListLoading = false;
            state.notifyMessage = "Error while fetching payer list";
            state.statusTag = "error";
        })
        builder.addCase(searchPayerList.fulfilled, (state, actions) => {
            state.payerListLoading = false;
            if (actions.payload.length > 0) {
                state.payersList = actions.payload || [];
            } else {
                state.payerListLoading = false;
                state.payersList = [];
            }
        })

        builder.addCase(getpatientClaimList.pending, (state) => {
            state.payerListLoading = true;
        })
        builder.addCase(getpatientClaimList.rejected, (state) => {
            state.payerListLoading = false;
            state.notifyMessage = "Error while fetching claim list";
            state.statusTag = "error";
        })
        builder.addCase(getpatientClaimList.fulfilled, (state, actions) => {
            state.payerListLoading = false;
            if (actions.payload.length > 0) {
                state.masterClaims = actions.payload || [];
            } else {
                state.payerListLoading = false;
                state.masterClaims = [];
            }
        })

        builder.addCase(GetAdjustmentTypes.fulfilled, (state, actions) => {
            if (actions.payload.length > 0) {
                state.adjustmentTypes = actions.payload || [];
            } else {
                state.adjustmentTypes = [];
            }
        })

        builder.addCase(getClaimStatusAndSubStatus.fulfilled, (state, actions) => {
            const {
                claimStatusList,
                claimSubStatusList
            } = actions.payload;

            state.claimStatusList = claimStatusList;
            state.claimSubStatusList = claimSubStatusList
        })

        builder.addCase(getAdjustmentCodeList.fulfilled, (state, actions) => {
            if (actions.payload.data.length > 0) {
                const codeList = actions.payload.data.filter(
                    (item) =>
                        !(Object.prototype.hasOwnProperty.call(item, "label") && /^\d+$/.test(item.label))
                )
                if (actions.payload.field == "adjustment1") {
                    state.adjustment_1_codes_list = codeList || [];
                } else {
                    state.adjustment_2_codes_list = codeList || [];
                }
            } else {
                if (actions.payload.field == "adjustment1") {
                    state.adjustment_1_codes_list = [];
                } else {
                    state.adjustment_2_codes_list = [];
                }
            }
        })

        builder.addCase(postPaymentManually.fulfilled, (state) => {
            state.paymentPostLoading = false;
        })

        builder.addCase(onSavePayment.pending, (state) => {
            state.paymentPostLoading = true;
        })

        builder.addCase(onSavePayment.rejected, (state) => {
            state.paymentPostLoading = false;
        })

        builder.addCase(onSavePayment.fulfilled, (state, action) => {
            state.paymentPostLoading = false;
            if (action.payload.status == "201") {
                state.showNotification = true;
                state.notifyMessage = i18n.t("successMessages.payment_posted");
                state.statusTag = "success";
                state.postPaymentDetails.paymentPK = action.payload.data.id;
                state.clickedFromTable = true;
            } else if (action.payload.status === 500 || action.payload.status === 400) {
                state.showNotification = true;
                state.notifyMessage = i18n.t("errorMessages.payment_error");
                state.statusTag = "error";
            }
        })

        builder.addCase(onSavePatientPayment.pending, (state) => {
            state.paymentPostLoading = true;
        })

        builder.addCase(onSavePatientPayment.rejected, (state) => {
            state.paymentPostLoading = false;
        })

        builder.addCase(onSavePatientPayment.fulfilled, (state, actions) => {
            state.paymentPostLoading = false;
            if (actions.payload.postType === 3) {
                window.location.reload();
            }

            if (actions.payload.status === 200 || actions.payload.status === 201) {
                state.showNotification = true;
                state.notifyMessage = i18n.t("successMessages.payment_posted");
                state.statusTag = "success";
                state.postPaymentDetails.paymentPK = actions.payload.data.payment_pk;
                state.postPaymentDetails.patientPaymentPatientPK = actions.payload.data.patient_pk;
                state.postPaymentDetails.patientPaymentPostType = actions.payload.data.post_type
            }
            else if (actions.payload.status === 500) {
                state.showNotification = true;
                state.notifyMessage = i18n.t("errorMessages.payment_error");
                state.statusTag = "success";
            }
        })

        builder.addCase(getPaymentDetails.pending, (state) => {
            state.getPaymentDetailsLoading = true;
            state.isEditButtondisable = true;
        })

        builder.addCase(getPaymentDetails.rejected, (state) => {
            state.getPaymentDetailsLoading = false;
            state.isEditButtondisable = true;
        })

        builder.addCase(getPaymentDetails.fulfilled, (state, actions) => {
            state.getPaymentDetailsLoading = false;
            state.clickedFromTable = false;
            let { claimList } = current(state);
            if (actions.payload) {
                let data = {
                    ...actions.payload,
                    unAppliedAmount: actions.payload["total_amount"] ?? 0,
                    appliedAmount: 0,
                    payer: actions.payload.era_payer ? actions.payload.era_payer : actions.payload.payer
                }
                state.editPaymentVariables = {
                    ...actions.payload,
                    payer: actions.payload.era_payer ? actions.payload.era_payer : actions.payload.payer
                }
                state.isEditButtondisable = false;
                let selectedPayers = [{
                    "id": actions.payload.payer,
                    "name": actions.payload.payer_name,
                    "alert": ""
                }]

                state.selectedPayer = selectedPayers;


                if (Object.keys(claimList).length > 0 && !actions.payload["has_procedurs"]) {
                    const { appliedAmount, unAppliedAmount } = updateAppliedAndUnappliedAmount(state.claimList, data);
                    data = {
                        ...data,
                        unAppliedAmount: unAppliedAmount,
                        appliedAmount: appliedAmount
                    }
                }
                state.insurancePaymentData = data;
            }
        })

        builder.addCase(getPatientPaymentData.pending, (state) => {
            state.paymentPostLoading = true;
        })

        builder.addCase(getPatientPaymentData.rejected, (state) => {
            state.paymentPostLoading = false;
        })

        builder.addCase(getPatientPaymentData.fulfilled, (state, actions) => {
            let patientPostDetails = current(state).patientPostDetails
            state.paymentPostLoading = false;
            if (actions.payload.data) {
                state.patientPostDetails.patientId = actions.payload.data.patient_id;
                let { sumApplied, alreadyApplied, unApplied } = getSumAppliedUnApplied(actions.payload.data, patientPostDetails);
                let data = {
                    ...state.patientPostDetails,
                    unAppliedAmount: unApplied,
                    sumApplied: sumApplied,
                    alreadyApplied: alreadyApplied
                }
                state.patientPostDetails = data;
                state.patientPaymentData = actions.payload.data;
                state.patientPostDetails.paymentBalnceAmount = actions.payload.data.balance_amount;
                state.patientPostDetails.PrintReceiptonsaving = actions.payload.data.print_receipt;
                state.editPaymentVariables = actions.payload.data;
                if (!actions.payload.updatePayment) {
                    let data = {
                        ...state.patientPostDetails,
                        showContinuePosting: true,
                        showPreview: true
                    }

                    state.patientPostDetails = data;
                }
            }
        })

        builder.addCase(checkForAlert.pending, (state) => {
            state.paymentPostLoading = true;
        })

        builder.addCase(checkForAlert.rejected, (state) => {
            state.paymentPostLoading = false;
        })

        builder.addCase(checkForAlert.fulfilled, (state, actions) => {
            if (actions.payload.has_alert) {
                state.showAlertMessage = true;
                state.alertMessage = actions?.payload?.alert_message ? actions?.payload?.alert_message : ""
            }
        })



        builder.addCase(searchClaimIDS.fulfilled, (state, actions) => {
            state.masterClaims = actions.payload.data;
        })

        builder.addCase(listPatientDropdownData.fulfilled, (state, actions) => {
            state.patientDropdown = actions.payload;
        })

        builder.addCase(listPatientClaims.pending, (state) => {
            state.searchClaimLoading = true;
        })

        builder.addCase(listPatientClaims.rejected, (state) => {
            state.searchClaimLoading = false;
        })

        builder.addCase(listPatientClaims.fulfilled, (state, actions) => {
            state.searchClaimLoading = false;
            if (actions.payload.length > 1) {
                state.searchModalOpen = true;
                state.patientClaimsData = actions.payload;
                state.header = i18n.t("payments.post_payments.insurancePayment.searchResults");
            }
        })

        builder.addCase(listProcedureClaims.pending, (state) => {
            state.searchClaimLoading = true;
            state.dataLoading = true;
        })

        builder.addCase(listProcedureClaims.rejected, (state) => {
            state.searchClaimLoading = false;
            state.dataLoading = false;
        })

        builder.addCase(listProcedureClaims.fulfilled, (state, actions) => {
            state.searchClaimLoading = false;
            state.dataLoading = false;
            let jsonObject = createJsonData(actions.payload.data, state.insurancePaymentData.id, state.insurancePaymentData);
            state.claimList = { ...state.claimList, ...jsonObject };
        })

        builder.addCase(getPatientPaymentClaimDetails.pending, (state) => {
            state.dataLoading = true;
        })

        builder.addCase(getPatientPaymentClaimDetails.rejected, (state) => {
            state.dataLoading = false;
        })

        builder.addCase(getPatientPaymentClaimDetails.fulfilled, (state, actions) => {
            state.dataLoading = false;
            if (actions?.payload?.results.length > 0) {
                let jsonObject = createJsonDataPatientPayment(actions.payload.results);
                state.patientClaims = { ...state.patientClaims, ...jsonObject };
            } else {
                state.patientClaims = {};
            }
        })

        builder.addCase(savedProcedureData.pending, (state) => {
            state.savedProcedureLoading = true;
            state.dataLoading = true;
        })

        builder.addCase(savedProcedureData.rejected, (state) => {
            state.savedProcedureLoading = false;
            state.dataLoading = false;
        })

        builder.addCase(savedProcedureData.fulfilled, (state, actions) => {
            state.savedProcedureLoading = false;
            state.dataLoading = false;
            if (actions.payload.action === "get") {
                let jsonObject = createJsonData(actions.payload.data, actions.payload.pk, state.insurancePaymentData);
                state.claimList = { ...state.claimList, ...jsonObject };
            }

            const { appliedAmount, unAppliedAmount } = updateAppliedAndUnappliedAmount(state.claimList, state.insurancePaymentData);
            let data = {
                ...state.insurancePaymentData,
                unAppliedAmount: unAppliedAmount,
                appliedAmount: appliedAmount
            }

            state.insurancePaymentData = data;

        })

        builder.addCase(handleProcedureSubmit.pending, (state) => {
            state.saveProcedureLoading = true;
        })

        builder.addCase(handleProcedureSubmit.rejected, (state) => {
            state.saveProcedureLoading = false;
        })

        builder.addCase(handleProcedureSubmit.fulfilled, (state, actions) => {
            state.saveProcedureLoading = false;
            if (actions?.payload?.status === "success") {
                state.showNotification = true;
                state.notifyMessage = "Payment Updated...";
                state.statusTag = "success";
                state.saveTriggered = true;
            } else {
                if (actions?.payload?.message === 'invalid_responsibility_type') {
                    state.showNotification = true;
                    state.notifyMessage = `Claim status selected not matching with the Priority on Procedure ${actions?.payload?.data?.cpt ?? ""}`;
                    state.statusTag = "error";
                } else if (actions?.payload?.message === 'cpt_or_claim_status_missing') {
                    state.showNotification = true;
                    state.notifyMessage = `Procedure or the claim status is missing in a procedure row.`
                    state.statusTag = "error";
                } else {
                    state.showNotification = true;
                    state.notifyMessage = "An error occurred while posting the payment."
                    state.statusTag = "error";
                }
            }
        })

        builder.addCase(GetPatientPaymentPlans.fulfilled, (state, actions) => {
            if (actions.payload.payment_plan != "") {
                state.paymentPlanList = actions.payload;
                state.paymentPlan_status=true;
            }
            else{
                state.paymentPlan_status=false;
            }
        })

        builder.addCase(CheckForPaymentExists.pending, (state) => {
            state.paymentPostLoading = true;
        })

        builder.addCase(CheckForPaymentExists.fulfilled, (state, actions) => {
            state.paymentPostLoading = false;
            if (actions.payload.data.payment_exists) {
                state.alertMessage = "Payment Already Exists";
                state.showAlertMessage = true;
            } else if (actions.payload.postType == "credit") {
                state.showPostAsCreditModal = true;
                state.header = i18n.t("payments.post_payments.postAsCreditModalHeader")
            } else {
                if (actions.payload.postType === "manually") {
                    if (!state.patientPostDetails.postManually) {
                        //CHECK ALL MANDATORY FIELDS ARE FILLED
                        let data = {
                            ...state.patientPostDetails,
                            postManually: !state.patientPostDetails.postManually,
                            autoPost: false
                        }
                        state.patientPostDetails = data;
                    } else {
                        let data = {
                            ...state.patientPostDetails,
                            postManually: !state.patientPostDetails.postManually,
                            autoPost: false
                        }
                        state.patientPostDetails = data;
                    }
                } else {
                    if (!state.patientPostDetails.autoPost) {
                        //CHECK ALL MANDATORY FIELDS ARE FILLED
                        let data = {
                            ...state.patientPostDetails,
                            postManually: false,
                            autoPost: !state.patientPostDetails.autoPost
                        }
                        state.patientPostDetails = data;
                    } else {
                        let data = {
                            ...state.patientPostDetails,
                            postManually: false,
                            autoPost: !state.patientPostDetails.autoPost
                        }
                        state.patientPostDetails = data;
                    }
                }
            }
        })

        builder.addCase(CheckForPaymentExists.rejected, (state, actions) => {
            state.showNotification = true;
            state.notifyMessage = actions.payload.message;
            state.statusTag = actions.payload.tag;
            state.paymentPostLoading = false;
        })

        builder.addCase(getResponibilityTypePatient.fulfilled, (state, actions) => {
            state.responsibilityData = actions.payload.responsibility_list;
        })

        builder.addCase(ListPatientAppliedClaims.pending, (state) => {
            state.dataLoading = true;
        })

        builder.addCase(ListPatientAppliedClaims.rejected, (state) => {
            state.dataLoading = false;
        })

        builder.addCase(ListPatientAppliedClaims.fulfilled, (state, actions) => {
            state.patientAppliedProcedures = actions.payload;
            state.patientPostDetails.showContinuePosting = false;
            state.patientPostDetails.showAppliedPayments = true;
        })

        builder.addCase(handleRadioButtonChange.fulfilled, (state, action) => {
            const { radioValue, result } = action.payload;
            state.patientPostDetails.radioValue = radioValue;
            let amountToApply = Number(state.patientPaymentData.amount);
            result.forEach((item) => {
                state.patientClaims[item].procedures = current(state.patientClaims[item].procedures).map((procedure) => {
                    let procedureOpeningBalance = parseFloat(procedure.opening_balance).toFixed(2);
                    let updatedProcedure;

                    if (procedureOpeningBalance >= amountToApply) {
                        updatedProcedure = {
                            ...procedure,
                            apply_payment: parseFloat(amountToApply).toFixed(2),
                            responsibility_type: 4,
                            closing_balance: procedure.opening_balance - amountToApply,
                            claim_status_id: "",
                            claim_sub_status_id: ""
                        };
                        amountToApply = 0;
                    } else {
                        updatedProcedure = {
                            ...procedure,
                            apply_payment: parseFloat(procedureOpeningBalance).toFixed(2),
                            responsibility_type: 5,
                            closing_balance: 0.00,
                            claim_status_id: "",
                            claim_sub_status_id: ""
                        };
                        amountToApply -= procedureOpeningBalance;
                    }

                    return updatedProcedure;
                });
            });
        });
        builder.addCase(handleRadioButtonChange.rejected, (state, action) => {
            state.showNotification = true;
            state.notifyMessage = action.error.message || "An error occurred";
            state.statusTag = "error";
        });


        builder.addCase(onSavePatientPaymentProcedure.pending, (state) => {
            state.paymentPostLoading = true;
        })

        builder.addCase(onSavePatientPaymentProcedure.fulfilled, (state, actions) => {
            state.paymentPostLoading = false;
            if (actions.payload.status == 200) {
                state.showNotification = true;
                state.notifyMessage = "Payment Updated...";
                state.statusTag = "success";
                if (state.patientPaymentVariables.PrintReceiptonsaving || state.patientPostDetails.PrintReceiptonsaving) {
                    setStorage("paymentPK", actions.payload.pk);
                    state.redirectToReceipt = true;
                }

                state.saveTriggered = true;
            } else {
                if (actions.payload.data?.message === 'invalid_responsibility_type') {
                    state.showNotification = true;
                    state.notifyMessage = `Claim status selected not matching with the Priority on Procedure ${actions.payload?.data?.data?.cpt ?? ""}`;
                    state.statusTag = "error";
                }

                if (actions.payload.data?.message === 'cpt_or_claim_status_missing') {
                    state.showNotification = true;
                    state.notifyMessage = `Procedure or the claim status is missing in a procedure row.`;
                    state.statusTag = "error";
                }
            }
        })

        builder.addCase(onSavePatientPaymentProcedure.rejected, (state, actions) => {
            state.paymentPostLoading = false;
            if (actions.payload.action === "error") {
                state.showNotification = true;
                state.notifyMessage = actions.payload.message;
                state.statusTag = "error";
            }

            if (actions.payload.action === "validation_error") {
                state.validationError = actions.payload.message;
                state.validationErrorModal = true;
            }

            if (actions.payload.action === "alert") {
                state.alertMessage = actions.payload;
                state.showAlertMessage = true;
            }
        })

    }

});

export const { setPostPaymentType, updatePayerDetails, resetNotifyWindow, updateDate, updateValueChange, validationErrorShowing, onContinuePosting, onHandleClaimIDChange,
    resetPaymentDetails, showNotification, resetAlertMessage, showAlertMessage, showLoadingBar, showModalWindow, hideModalWindow, handleUpdateTableData,
    storePaymentDetailsTableClick, updateClaimSearchVariables, procedureValueChange, addAdjustmentCode1, removeAdjustmentCode, addAdjustmentAmount, resetSearchVariable,
    addAdjustmentCode2, onAddRemarkCodes, openClaimModalWindow, handleProcedureEditWindowClose, onFieldBlurApply, removeAllPaymentDetails, updateQueryDetails,
    onHandleSearchClaimDateChange, onHandleValueChange, removeClaimFromTable, removeProcedureFromTable, reversePaymentData, onHandleEditPayment, onHandleEditPaymentDate,
    removeAdjustmentCodeList, onHandleAdvanceSearchFields, setFormError, setPatientPostDetails, onChangePostPaymentType, onHandleApplyPaient, openPatientModalWindow,
    handlePatientTableDataUpdate,onPatientDropDownSelectAction,resetPatientProcedureDetails,resetToInitialState
} = paymentSlice.actions;

export default paymentSlice.reducer;