import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../../service";
import { DEFAULT_ADJUSTMENT_VALUE, DEFAULT_PAGING_SIZE, PAYEMNT_MODES_CREDITCARD_ID } from "../../../../utilities/staticConfigs";
import { getStorage } from "../../../../utilities/browserStorage";
import { hideModalWindow, onChangePostPaymentType, showAlertMessage, showLoadingBar, showModalWindow, showNotification, updateQueryDetails, updateValueChange, validationErrorShowing } from "./paymentSlice";
import { checkForValidationError, checkForValidationErrorPatient, formatDate, generatePatientSaveData, generateSaveData, isValidDate, postDatagenerator, postPaymentValidations, sortByDate } from "./paymentDataProcessor";
import i18n from "../../../../utilities/i18n";
import { formatDateUS } from "../../../../utilities/commonUtilities";
export const searchPayerList = createAsyncThunk(
    "payments/payersearch",
    async (payload, { rejectWithValue }) => {
        try {
            let result = await service.ListInsuranceNames(DEFAULT_PAGING_SIZE, 0,payload.practicePK, payload.searchValue)
            return result.data;
        } catch (error) {
            return rejectWithValue("Error while fetching opened parent claim opened tabs.");
        }
    }
)


export const GetAdjustmentTypes = createAsyncThunk(
    "payments/adjustmentType",
    async (payload, { rejectWithValue }) => {
        try {
            let result = await service.GetListAdjustmentTypes(DEFAULT_PAGING_SIZE, 0, "dropdown")
            return result.data
        } catch (error) {
            return rejectWithValue("Error while fetching opened parent claim opened tabs.");
        }
    }
)


export const GetPatientPaymentPlans = createAsyncThunk(
    "payments/GetPatientPaymentPlans",
    async (payload, { rejectWithValue }) => {
        try {
            let result = await service.getPatientPaymentPlans(payload);
            return result.data;
        } catch (error) {
            return rejectWithValue("Error while fetching opened parent claim opened tabs.");
        }
    }
)


export const postPaymentManually = createAsyncThunk(
    "payments/postPaymentManually",
    async (payload, { dispatch, getState }) => {
        try {
            dispatch(showLoadingBar(true));
            const state = getState();
            let { paymentData } = state;
            const { errorMessage, isError, status } = postPaymentValidations(paymentData);
            if (isError) {
                dispatch(showNotification({ message: errorMessage, tag: status }));
                dispatch(showLoadingBar(false));
            } else {
                if (
                    paymentData.insurancePaymentVariables["paymentDate"] &&
                    paymentData.insurancePaymentVariables["reference_check_number"] &&
                    paymentData.insurancePaymentVariables["amount"] &&
                    paymentData.insurancePaymentVariables["payment_mode"] &&
                    ((paymentData.insurancePaymentVariables["payment_mode"] ===
                        PAYEMNT_MODES_CREDITCARD_ID &&
                        paymentData.insurancePaymentVariables["card_type"]) ||
                        paymentData.insurancePaymentVariables["payment_mode"] !==
                        PAYEMNT_MODES_CREDITCARD_ID)
                ) {
                    let data = postDatagenerator(paymentData);
                    const result = await service.CheckForPaymentExists(data);
                    if (result.data) {
                        if (result.data.payment_exists) {
                            dispatch(showAlertMessage("Payment Already Exists"));
                        } else if (payload == "credit") {
                            dispatch(showModalWindow({ key: "showPostAsCreditModal", open: true, header: i18n.t("payments.post_payments.postAsCreditModalHeader") }))
                        } else {
                            dispatch(onSavePayment())
                        }
                    }
                }
                else {
                    dispatch(showLoadingBar(false));
                }
            }
        } catch (error) {
            dispatch(showLoadingBar(false));
        }
    }
)


export const CheckForPaymentExists = createAsyncThunk(
    "payments/CheckForPaymentExists",
    async (payload, { getState, dispatch, rejectWithValue }) => {
        const state = getState();
        let { paymentData } = state;
        let data = { ...paymentData.patientPaymentVariables };
        let postData = { ...paymentData.postPaymentDetails }
        if (data.paymentType == 2 && data.paymentSubType == '') {
            return rejectWithValue({ message: "Please select a subtype", tag: "error" })
        }

        if (parseFloat(data.paymentAmount) === 0) dispatch(updateValueChange({ name: "paymentAmount", value: "", key: "patientPaymentVariables" }));
        if (
            payload.selectPatient &&
            data.payment_date &&
            data.paymentAmount &&
            parseFloat(data.paymentAmount) > 0 &&
            data.paymentType &&
            data.paymentMode &&
            ((data.paymentMode === PAYEMNT_MODES_CREDITCARD_ID && data.creditCard) ||
                data.paymentMode !== PAYEMNT_MODES_CREDITCARD_ID)
        ) {
            if (postData.paymentPK) dispatch(onChangePostPaymentType(payload.key));
            else {
                if (data.paymentMode !== 1 && data.paymentReference === "") {
                    return rejectWithValue({
                        message: i18n.t(
                            "payments.post_payments.error.refrence_check_issue"
                        ), tag: "error"
                    })
                } else {
                    let apiData = {
                        reference_check_number: data.paymentReference,
                        amount: data.paymentAmount.replace(/,/g, ""),
                        payment_date: data.payment_date ? data.payment_date : "",
                        payment_type: "patient",
                        patient_id: payload.patientPK
                    };

                    const result = await service.CheckForPaymentExists(apiData);
                    return { data: result.data, postType: payload.type };
                }
            }
        }else{
            return rejectWithValue("Something went wrong")
        }
    })


export const ListPatientAppliedClaims = createAsyncThunk(
    "payments/ListPatientAppliedClaims",
    async (payload) => {
        const result = await service.GetPatientAppliedPayments(payload);
        return result.data;
    }
)

export const onSavePayment = createAsyncThunk(
    "payments/onSavePayment",
    async (payload, { getState }) => {
        const state = getState();
        let { paymentData } = state;
        let data = { ...paymentData.insurancePaymentVariables };
        if (!data["level_adjustments"]) data["level_adjustments"] = 0;
        const result = await service.SaveInsurancePayment(data);
        return result;
    })

export const onSavePatientPayment = createAsyncThunk(
    "payments/onSavePatientPayment",
    async (payload, { getState }) => {
        const state = getState();
        let { paymentData } = state;
        let data = { ...paymentData.patientPaymentVariables };
        let postType = paymentData.patientPostDetails.postType;
        let apidata = {
            patient: payload,
            payment_date: data.payment_date ? data.payment_date : "",
            reference_check_number: data.paymentReference,
            amount: data.paymentAmount.replace(/,/g, ""),
            payment_mode: data.paymentMode,
            note: data.paymentNote,
            post_type: postType,
            payment_plan: data.selectedPaymentPlan,
            print_receipt: data.PrintReceiptonsaving,
            secondary_payment_type: data.paymentType,
            secondary_payment_subtype: (data.paymentType == 1) ? 3 : data.paymentSubType,
        };

        if (data.paymentMode === PAYEMNT_MODES_CREDITCARD_ID) apidata={...apidata,card_type:data.creditCard}
        let result = await service.SavePostPayment(apidata);
        return { data: result.data, status: result.status, postType: postType }
    })

export const getPaymentDetails = createAsyncThunk(
    "payments/getPaymentDetails",
    async (payload, { dispatch }) => {
        const result = await service.GetInsurancePayment(payload.pk);
        if (result.data["has_procedurs"]) {
            dispatch(savedProcedureData({ pk: payload.pk, action: payload.action }))
        }
        return result.data
    }
)

export const savedProcedureData = createAsyncThunk(
    "payments/savedProcedureData",
    async (payload) => {
        const result = await service.SavedInsurancePaymentProcudures(payload.pk);
        return { data: result.data, pk: payload.pk, action: payload.action };
    }
)


export const getPatientPaymentData = createAsyncThunk(
    "payments/getPatientPaymentData",
    async (payload, { dispatch }) => {
        const result = await service.GetPaymentData(payload.paymentPK);
        if (result.data.patient_id) {
            if (payload.action !== "edit") {
                dispatch(checkForAlert(result.data.patient_id));
            }
            dispatch(getResponibilityTypePatient(result.data.patient_id));
        }
        return { data: result.data, updatePayment: payload.updatePayment };
    }
)


export const getResponibilityTypePatient = createAsyncThunk(
    "payments/getResponibilityTypePatient",
    async (payload) => {
        const result = await service.GetResponsibilityType(payload);
        return result.data;
    }
)


export const checkForAlert = createAsyncThunk(
    "payments/checkForAlert",
    async (payload) => {
        const result = await service.CheckForAlert(payload);
        return result.data;
    }
)

export const getpatientClaimList = createAsyncThunk(
    "payment/getPatientClaimList",
    async (payload) => {
        const result = await service.ListClaimsDropDown(
            payload.query,
            payload.patientPK,
            getStorage("practice"));
        return result.data;
    }
);


export const getClaimStatusAndSubStatus = createAsyncThunk(
    "payments/getClaimStatusAndSubStatus",
    async (payload, thunkAPI) => {
        try {
            const promises = [
                await service.ListClaimStatus(DEFAULT_PAGING_SIZE, 0, getStorage('practice'), null).then((response) => response.data),
                await service.ListClaimStatusSubStatus(DEFAULT_PAGING_SIZE, 0, getStorage('practice')).then((response) => response.data)
            ]

            const [
                claimStatusList,
                claimSubStatusList
            ] = await Promise.all(promises);

            return {
                claimStatusList,
                claimSubStatusList
            };
        } catch (error) {
            return thunkAPI.rejectWithValue('Error fetching claim information:' + error.message)
        }
    })


export const searchClaimIDS = createAsyncThunk(
    "payments/searchClaimIDS",
    async (payload, { getState }) => {
        const state = getState();
        let { paymentData } = state;
        payload = payload + `&insurances=[${paymentData.insurancePaymentData.payer}]`;
        payload = payload + '&filter=1';
        const result = service.ListClaimsDropDown(
            payload,
            paymentData.claimSearchVariables.patient_id,
            getStorage("practice")
        );
        return result;
    }
)

export const listPatientDropdownData = createAsyncThunk(
    "payments/listPatientDropdownData",
    async (payload, { getState }) => {
        const state = getState();
        let { paymentData } = state;
        if (payload) payload += "&practice_pk=" + getStorage('practice');
        else payload = "practice_pk=" + getStorage('practice');
        payload = payload + `&insurances=[${paymentData.insurancePaymentData.payer}]`;
        const result = await service.GetListPatientDropdown(payload);
        return result.data;
    }
)

export const listPatientClaims = createAsyncThunk(
    "payments/listPatientClaims",
    async (payload, { getState, dispatch }) => {
        const state = getState();
        const addQueryParam = (key, value) => {
            if (value !== "") {
                query += `&${key}=${value}`;
            }
        };
        let { paymentData } = state;
        let page = 0;
        let query;
        query = "?page_size=" + DEFAULT_PAGING_SIZE + "&page=" + page;
        if (payload.type === "patient") {
            const paymentDate = "&payment_dos=" + paymentData?.insurancePaymentData?.payment_date;
            query += "&patient_pk=" + payload.value + paymentDate;
            dispatch(updateQueryDetails(query));
        } else if (payload.type === "advanced") {
            let dataObj = { ...paymentData.claimSearchVariables };
            query += "&filter=true";
            addQueryParam("claim_status_pk", dataObj["claim_status"]);
            addQueryParam("claim_type", dataObj["claim_type"]);
            addQueryParam("dos_from", formatDate(dataObj["claim_date_of_service_from"]));
            addQueryParam("dos_to", formatDate(dataObj["claim_date_of_service_to"]));
            addQueryParam("submitted_from_date", formatDate(dataObj["claim_submitted_date_from"]));
            addQueryParam("submitted_to_date", formatDate(dataObj["claim_submitted_date_to"]));
            addQueryParam("res_type_pk", dataObj["responsibility_type"]);
            dispatch(updateQueryDetails(query));
        } else {
            query = payload.value;
        }
        const result = await service.ListInsurancePaymentClaims(query);
        if (result.data.length === 1) {
            dispatch(listProcedureClaims(result.data[0].id))
        }

        return result.data.length === 1 ? [] : result.data;
    }
)


export const getPatientPaymentClaimDetails = createAsyncThunk(
    "payments/getPatientPaymentClaimDetails",
    async (payload, { getState }) => {
        const state = getState();
        let { paymentData } = state;
        const result = await service.ListClaimDetails(
            payload.patientPK,
            payload.pageSize,
            payload.page,
            paymentData.patientPostDetails.claimPK,
            paymentData.selectCharge,
            paymentData.postPaymentDetails.paymentPK,
            paymentData.patientPaymentVariables.includeFamilyMemberBalances
        );

        return result.data;
    })


export const handleRadioButtonChange = createAsyncThunk(
    'patientClaims/handleRadioButtonChange',
    async (payload, { getState }) => {
        const state = getState();
        let { paymentData } = state;
        if (paymentData.patientPostDetails.unAppliedAmount > 0) {
            const result = await sortByDate(payload, paymentData.patientClaims);
            return { radioValue: payload, result };
        } else {
            throw new Error(i18n.t("errorMessages.Invalid_balance"));
        }
    }
);


export const listProcedureClaims = createAsyncThunk(
    "payments/listProcedureClaims",
    async (payload, { getState }) => {
        const state = getState();
        let { paymentData } = state;
        const paymentDos = paymentData.editPaymentVariables.payment_date;
        let page = 0;
        const response = await service.ListInsurancePaymentProcedures(
            payload,
            DEFAULT_PAGING_SIZE,
            page,
            paymentDos
        );
        return { data: response.data, pk: paymentData.insurancePaymentData.payment };
    }
)

export const getAdjustmentCodeList = createAsyncThunk(
    "payments/getAdjustmentCodeList",
    async (payload) => {
        payload.query += "&practice_pk=" + getStorage("practice");
        const result = await service.GetListAdjustmentReasonCode(
            DEFAULT_PAGING_SIZE,
            0,
            payload.query,
            "auto"
        );
        return { data: result.data, field: payload.field }

    }
)

export const handleProcedureSubmit = createAsyncThunk(
    "payments/handleProcedureSubmit",
    async (payload, { getState, dispatch }) => {
        const state = getState();
        let { paymentData } = state;
        let { claimList, insurancePaymentData } = paymentData;
        const { pk, saveData } = await generateSaveData(claimList, insurancePaymentData);
        const { isError, validationError } = await checkForValidationError(claimList, insurancePaymentData);
        if (isError) {
            dispatch(validationErrorShowing({ errors: validationError, open: true }))
            return
        } else {
            let data = {
                "current_practice_id": getStorage("practice"),
                "payment_pk": pk, "procedures_data": saveData
            }
            const response = await service.SaveInsurancePaymentProcedures(data);
            return response.data;
        }
    }
)

export const onSavePatientPaymentProcedure = createAsyncThunk(
    "payments/onSavePatientPaymentProcedure",
    async (payload, { getState, rejectWithValue }) => {
        const state = getState();
        let { paymentData } = state;
        let { patientClaims,patientPostDetails} = paymentData;
        const saveData = await generatePatientSaveData(patientClaims, payload);
        const { isError, validationError } = await checkForValidationErrorPatient(patientClaims);
        if (saveData.length > 0 && !isError) {
            if(patientPostDetails.unAppliedAmount<0){
                return rejectWithValue({message:"Total applied amount should be less than or equal to total payment amount.",action:"alert"})
            }else{
            let data = {
                payment: payload.paymentPK,
                procedures: saveData,
            };
            const result = await service.SavePatientPaymentProcedures(data);
            return { status: result.status, data: result.data,pk:payload.paymentPK };
            }
        } else {
            if(saveData.length==0){
            return rejectWithValue({message:"No data added to save",action:"error"})
            }

            if(Error){
                return rejectWithValue({message:validationError,action:"validation_error"})
            }
        }
    }
)

export const onUpdatePatientPaymentDetails = createAsyncThunk(
    "payments/handleOnEditPayment",
    async (payload, { getState, dispatch }) => {
        const state = getState();
        let { paymentData } = state;
        let { editPaymentVariables } = paymentData;
        if (!isValidDate(editPaymentVariables.payment_date)) {
            dispatch(showNotification({ message: i18n.t("payments.post_payments.editPaymentModal.invalid_date"), tag: "error" }));
            return;
        }

        let data = {
            custom_payment_id: editPaymentVariables.custom_payment_id,
            payment_date: editPaymentVariables.payment_date,
            reference_check_number: editPaymentVariables.reference_check_number,
            card_type: editPaymentVariables.card_type,
            amount: editPaymentVariables.amount,
            secondary_payment_type: editPaymentVariables.secondary_payment_type,
            secondary_payment_subtype: editPaymentVariables.secondary_payment_subtype,
            payment_mode: editPaymentVariables.payment_mode,
            patient_name: editPaymentVariables.patient_name,
            note: editPaymentVariables.note,
            secondary_payment_type_name: editPaymentVariables.secondary_payment_type_name,
            payment_mode_name: editPaymentVariables.payment_mode_name,
        }

        const result = await service.UpdatePostPayment(
            payload.paymentPK,
            data
        );
        if (result.data) {
            dispatch(hideModalWindow({ key: "showEditPaymentModal", open: false }));
            dispatch(getPatientPaymentData({ paymentPK: payload.paymentPK, updatePayment: payload.updatePayment, action: "edit" }));
        }
    }
)

export const handleOnEditPayment = createAsyncThunk(
    "payments/handleOnEditPayment",
    async (payload, { getState, dispatch }) => {
        const state = getState();
        let { paymentData } = state;
        let { editPaymentVariables, insurancePaymentData } = paymentData;
        let payment_allowed_amount =
            editPaymentVariables["amount"] -
            editPaymentVariables["level_adjustments"];
        if (!editPaymentVariables.payment_date || !editPaymentVariables.payment_mode || !editPaymentVariables.reference_check_number || !editPaymentVariables.amount || !editPaymentVariables.payer) {
            return;
        }

        if (editPaymentVariables.payment_date) {
            const dateObject = new Date(editPaymentVariables.payment_date);
            const today = new Date();
            if (isNaN(dateObject) || dateObject > today) {
                dispatch(showNotification({ message: i18n.t("payments.post_payments.insurancePayment.paymenMessages.paymentDateValidation"), tag: "error" }));
                return;
            }
        }

        if (insurancePaymentData.appliedAmount > payment_allowed_amount) {
            dispatch(showAlertMessage("Total posted amount should be greater than Total applied amount"));
        } else {
            const result = await service.UpdateInsurancePayment(
                payload,
                {
                    ...editPaymentVariables,
                    payment_date: formatDateUS(editPaymentVariables.payment_date),
                    level_adjustments: editPaymentVariables.level_adjustments ? editPaymentVariables.level_adjustments : DEFAULT_ADJUSTMENT_VALUE,
                    payer: editPaymentVariables.payer ?? null,
                    amount: editPaymentVariables['amount'] ? editPaymentVariables['amount'] : insurancePaymentData.amount
                }
            );
            if (result.status == 200) {
                dispatch(hideModalWindow({ key: "showEditPaymentModal", open: false }))
                dispatch(getPaymentDetails({ pk: payload, action: "edit" }));
            }
        }
    }
)

